import React, { useState } from "react";
import "./ImageZoom.css";

const ImageZoom = ({ src, alt, originalWidth, originalHeight }) => {
  const [isFullWidth, setIsFullWidth] = useState(false);

  const toggleSize = () => {
    setIsFullWidth(!isFullWidth);
  };

  return (
    <div className="image-container">
      <img
        src={src}
        alt={alt}
        className={isFullWidth ? "full-size" : "original-size"}
        onClick={toggleSize}
        style={{
          height: isFullWidth ? "auto" : originalHeight,
          width: isFullWidth ? "100%" : originalWidth,
          maxHeight: isFullWidth ? "800px" : "800px",
        }}
      />
    </div>
  );
};

export default ImageZoom;
