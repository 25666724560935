import "./ForWhom.css";
import ScrollToTopButton from "../../Components/ScrollToTopButton/ScrollToTopButton";
import Footer from "../../Components/Footer/Footer";

const ForWhom = () => {
  return (
    <div>
      <div className="main">
        <div className="whiteSpace" />
        <h2>Kommentare</h2>
        <br />
        <h3>Aufschlussreiches Feedback</h3>
        Abteilungsleiter
        <br />
        Operativer Service
        <br />
        Öffentlicher Dienst
        <br />
        <h4>
          Ein Coach, der weiterbringt – gute Analyse, hilfreiche Erklärungen,
          erweitert den Horizont
        </h4>
        Thomas Willmann hat mich über ca. 1,5 Jahre begleitet. Ihm gelang es
        sehr gut, Situationen zu analysieren, gezielt nachzufragen,
        Handlungsfelder einzukreisen und Handlungsoptionen aufzuzeigen. Mit
        guten Erläuterungen und praktischen Beispielen hat er mir neue
        Sichtweisen eröffnet und Möglichkeiten dargelegt mit Situationen
        umzugehen. Dabei ging er flexibel auf aktuelle Themen und Situationen
        ein und ihm gelang es auch, nicht griffigen Situationen und Empfindungen
        meinerseits auf den Grund zu gehen und Hintergründe und
        Verhaltensmöglichkeiten zu erarbeiten. Unsere Abschlussbesprechung war
        für mich eines der aufschlussreichsten, differenziertesten und
        vielschichtigsten Feedbacks, das ich je bekommen habe. Und dabei
        zugleich wertschätzend und hilfreich. Chapeau. Ein Coach, den ich ohne
        Einschränkungen wärmsten Herzens weiterempfehlen kann.
        <br />
      </div>

      <div className="blue">
        <div className="main">
          <h3>Richtungsweisende Ideen</h3>
          Bereichsleiterin Organisation
          <br />
          IT-Dienstleister
          <br />
          <h4>
            Sehr zielgerichtetes, professionelles und persönliches Coaching
          </h4>
          In der Zeit einer Übernahme eines neuen, größeren Bereiches konnte ich
          Herrn Willmann jederzeit als professionellen Sparringspartner mit sehr
          hoher Fachkompetenz und wertvollen, richtungsweisenden Ideen
          wahrnehmen. Durch die ausgeprägte soziale Kompetenz, das Eingehen auf
          meine jeweiligen Belange sowie das konkrete Feedback ist es gelungen,
          alle Themen zielführend umzusetzen. Eine sehr große Unterstützung!
          <br />
        </div>
      </div>

      <div className="main">
        <h3>Abwägen von Karrierealternativen</h3>
        Bereichsleiter
        <br />
        Internet und Onlinemedien
        <br />
        <h4>
          Super Kombination aus wissenschaftlichen Ansätzen und relevanter
          Praxiserfahrung
        </h4>
        Ich habe im Rahmen eines beruflichen Wechsels mit Thomas Willmann
        zusammengearbeitet. Hierbei ging es vor allem um die Herausarbeitung
        verschiedener Karrierealternativen und deren Abwägungen in Bezug auf
        meine berufliche und persönliche Entwicklung. Thomas Willmann hat mich
        systematisch und analytisch unterstützt, die für meine Situation beste
        Entscheidung zu treffen. Seine wissenschaftliche Arbeitsweise kombiniert
        mit seiner persönlichen Management-Erfahrung haben mir noch einmal neue
        Impulse gegeben und meinen Karriereweg auch über die ursprüngliche
        Zusammenarbeit hinaus beeinflusst. Ich kann Herrn Willmann
        uneingeschränkt als Coach (speziell für Management-bezogene Themen)
        empfehlen.
        <br />
      </div>

      <div className="blue">
        <div className="main">
          <h3>Change mit Kompetenz und Erfahrung</h3>
          Geschäftsführer
          <br />
          Öffentlicher Dienst
          <br />
          <h4>Soziale und fachliche Kompetenz in einer Person vereint</h4>
          Sehr strukturiertes und fachlich fundiertes Coaching durch Herrn
          Willmann. Geht sehr gut auf die spezifischen Belange des Auftraggebers
          ein und bereichert durch seine Methodenkompetenz, seine
          Sozialkompetenz und seine reichhaltigen beruflichen Erfahrungen, auch
          in Change-Prozessen. Zudem sehr angenehm in der persönlichen
          Zusammenarbeit.
          <br />
        </div>
      </div>

      <div className="main">
        <h3>Perfekter Start in die Führungsposition</h3>
        Abteilungsleiter
        <br />
        Drittvermittler & Direktverkauf
        <br />
        Finanzdienstleistungen
        <br />
        <h4>Individuelles und flexibles Coaching mit viel Fachverständnis!</h4>
        Thomas Willmann hat mich nach der Übernahme einer neuen
        Führungsverantwortung mit einem 100-Tage-Coaching begleitet. Alle Frage-
        und Problemstellungen einer "jungen" Führungskraft wurden ausführlich,
        sehr individuell und mit sehr viel Sachverstand behandelt. Es ergaben
        sich für mich viele wertvolle Impulse für meine tägliche Arbeit.
        Besonders gut hat gefallen, dass das Coaching digital in 1,5 Std.
        Sessions abgehalten wurde – die perfekte Gelegenheit, die Inhalte und
        Lösungsansätze in der Praxis anzuwenden! Durch die Begleitung über
        mehrere Monate konnten wir immer auf den vorherigen Einheiten aufbauen,
        aber auch spontan auf aktuelle Situationen eingehen. Herrn Willmann habe
        ich als absoluten Experten in der Finanz-/Bausparbranche erlebt, der es
        schafft, auch komplexe Fragestellungen mit viel Klarheit und
        Sachverstand „einfach“ zu transportieren. Vielen Dank für die sehr gute
        Vorbereitung!
        <br />
      </div>

      <div className="blue">
        <div className="main">
          <h3>Ziel erreicht</h3>
          Teamleiterin
          <br />
          Content Marketing und Brand Engagement
          <br />
          Telekommunikation
          <br />
          <h4>Tolle Zusammenarbeit</h4>
          Thomas Willmann ist ein Coach, den ich voll empfehlen kann: sachlich
          fundiert und menschlich sympathisch. Er schafft es, komplexe
          Sachverhalte sehr anschaulich und verständlich darzustellen und
          begreifbare und gut umsetzbare Lösungen anzubieten. Ich habe meine
          Zielsetzung zu 100 % erreicht: Fokussierung, Führungskompetenz
          ausbauen sowie klare Kommunikation im Führungsalltag.
          <br />
        </div>
      </div>

      <div className="main">
        <h3>Vom Konzept bis zum Markteintritt</h3>
        CEO & Co-Founder
        <br />
        Personaldienstleistungen
        <br />
        <h4>Eine klare Empfehlung! – Von der Idee zum Start-up</h4>
        Thomas Willmann hat unser Start-up-Team in der Konzeption, durch die
        Gründungsphase und bis zur Pilotierung am Markt begleitet. Uns hat
        besonders gut die methodische Vorgehensweise gefallen, die mit
        zahlreichen wertvollen Erfahrungen aus jahrelanger Berufspraxis ergänzt
        wurde; dies ist mit Sicherheit die richtige Basis für eine zielführende
        Beratung! Dennoch, eine wirklich gute Beratung baut auf Verständnis und
        Vertrauen. Und genau das ist der Grund dafür, warum die Zusammenarbeit
        für uns so gut funktioniert hat. Wir hatten zu jedem Zeitpunkt das
        Gefühl, dass wir uns in guten Händen befinden und dabei die richtigen
        Dinge richtig tun. Und daher kommt es bis heute nicht selten vor, dass
        sich jemand in einer langatmigen Diskussion auf einen der vielen
        Ratschläge besinnt, um die anderen wieder auf Kurs zu führen!
        <br />
      </div>

      <div className="blue">
        <div className="main">
          <h3>Konkrete Beispiele</h3>
          Bereichsleiterin
          <br />
          Head of HR Development & Reward
          <br />
          Finanzdienstleistungen
          <br />
          <h4>Sehr empfehlenswert</h4>
          Ein Coaching mit Herrn Willmann kann ich sehr empfehlen. Durch seine
          eigene langjährige Berufspraxis als Führungskraft verfügt er selbst
          über konkrete Beispiele und Erfahrungswerte, die das Coaching im
          Ergebnis gut greifbar und in die Praxis übertragbar machen.
          <br />
        </div>
      </div>

      <div className="main">
        <h3>Reicher Erfahrungsschatz</h3>
        Abteilungsleiter
        <br />
        Risikocontrolling / Meldewesen
        <br />
        Finanzdienstleistungen
        <br />
        <h4>Professionelles Coaching mit direkter Anbindung zur Praxis</h4>
        Das Coaching war geprägt von einer professionellen Bestandsaufnahme der
        Ist-Situation und einer klaren Beschreibung des Coaching-Zielbildes.
        Herr Willmann hat dabei das Können, stets die Bedürfnisse des Coachees
        aufzunehmen und durch seinen großen praktischen Erfahrungsschatz sowie
        durch seine exzellenten fachlichen Kenntnisse im Coaching-Bereich in ein
        wertvolles Beratungsgespräch zu übertragen.
        <br />
      </div>

      <div className="blue">
        <div className="main">
          <h3>Effektiv</h3>
          Founder und Technical Leader
          <br />
          Software
          <br />
          <h4>Sehr effektiv und echt klasse!</h4>
          Das Coaching war geprägt von einer professionellen Bestandsaufnahme
          <br />
        </div>
      </div>

      <div className="main">
        <h3>Wertvolle Praxis-Erfahrungen</h3>
        Bereichsleiterin
        <br />
        Kundenservice und Bestandsmanagement
        <br />
        Finanzdienstleistungen
        <br />
        <h4>Sehr guter und praxiserfahrener Coach</h4>
        <br />
      </div>
      <ScrollToTopButton />
      <Footer />
    </div>
  );
};
export default ForWhom;
