import React from "react";
import "./Home.css";
import Footer from "../../Components/Footer/Footer";
import Gallery from "../../Components/Gallery/Gallery";
import ScrollToTopButton from "../../Components/ScrollToTopButton/ScrollToTopButton";
import ImageZoom from "../../Components/ImageZoom/ImageZoom";

import Model from "../../Content/Icons/Model.svg";
import banner from "../../Content/Pictures/banner0.jpg";
import ImageP24 from "../../Content/Pictures/WillmannThomasPortraitserie24.jpg";
import Image47 from "../../Content/Pictures/WillmannThomas47.jpg";
import Image60 from "../../Content/Pictures/WillmannThomas60.jpg";

import Logo from "../../Content/Icons/Logo1.svg";

const Home = () => {
  const logoWidth = 30;
  const images = [
    {
      url: Image60,
      alt: "Thomas Willmann",
      title: "",
      subtitle: "",
      overlayStyle: {
        top: "60%",
        left: "20%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#777776",
        titleFontSize: "36px",
        subtitleColor: "#ffffff",
      },
      maxHeight: "320px",
      versionGallery: true,
    },

    {
      url: ImageP24,
      alt: "Thomas Willmann",
      title: "",
      subtitle: "",
      overlayStyle: {
        top: "60%",
        left: "30%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#777776",
        titleFontSize: "36px",
        subtitleColor: "#ffffff",
      },
      maxHeight: "320px",
      versionGallery: true,
    },

    {
      url: Image47,
      alt: "Thomas Willmann",
      title: "",
      subtitle: "",
      overlayStyle: {
        top: "70%",
        left: "76%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#777776",
        titleFontSize: "36px",
        subtitleColor: "#ffffff",
      },
      maxHeight: "320px",
      versionGallery: true,
    },
  ];
  const images1 = [
    {
      url: banner,
      alt: "Zitat",
      title: "",
      subtitle:
        "„Thomas Willmann ist ein Coach, den ich voll empfehlen kann: sachlich fundiert und menschlich sympathisch.“",
      overlayStyle: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#007bc0",
      },
      maxHeight: "320px",
      versionGallery: true,
    },
    {
      url: banner,
      alt: "Zitat",
      title: "",
      subtitle:
        "„Er schafft es, komplexe Sachverhalte sehr anschaulich und verständlich darzustellen und begreifbare und gut umsetzbare Lösungen anzubieten.“",
      overlayStyle: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#007bc0;",
      },
      maxHeight: "320px",
      versionGallery: true,
    },
    {
      url: banner,
      alt: "Zitat",
      title: "",
      subtitle:
        "„Geht sehr gut auf die spezifischen Belange des Auftraggebers ein und bereichert durch seine Methodenkompetenz, seine Sozialkompetenz und seine reichhaltigen beruflichen Erfahrungen, auch in Change-Prozessen.“",
      overlayStyle: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#007bc0;",
      },
      maxHeight: "320px",
      versionGallery: true,
    },
    {
      url: banner,
      alt: "Zitat",
      title: "",
      subtitle:
        "„Seine wissenschaftliche Arbeitsweise kombiniert mit seiner persönlichen Management-Erfahrung haben mir noch einmal neue Impulse gegeben und meinen Karriereweg auch über die ursprüngliche Zusammenarbeit hinaus beeinflusst.“",
      overlayStyle: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#007bc0;",
      },
      maxHeight: "320px",
      versionGallery: true,
    },
    {
      url: banner,
      alt: "Zitat",
      title: "",
      subtitle:
        "„Ich habe meine Zielsetzung zu 100 % erreicht: Fokussierung, Führungskompetenz ausbauen sowie klare Kommunikation im Führungsalltag.“",
      overlayStyle: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#007bc0;",
      },
      maxHeight: "320px",
      versionGallery: true,
    },
    {
      url: banner,
      alt: "Zitat",
      title: "",
      subtitle:
        "„Unsere Abschlussbesprechung war für mich eins der aufschlussreichsten, differenziertesten und vielschichtigsten Feedbacks, das ich je bekommen habe. Und dabei zugleich wertschätzend und hilfreich. Chapeau. Ein Coach, den ich ohne Einschränkungen wärmsten Herzens weiterempfehlen kann.“",
      overlayStyle: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#007bc0;",
      },
      maxHeight: "320px",
      versionGallery: true,
    },
    {
      url: banner,
      alt: "Zitat",
      title: "",
      subtitle:
        "„Alle Frage- und Problemstellungen einer ‚jungen‘ Führungskraft wurden ausführlich, sehr individuell und mit sehr viel Sachverstand behandelt.“ ",
      overlayStyle: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#007bc0;",
      },
      maxHeight: "320px",
      versionGallery: true,
    },
    {
      url: banner,
      alt: "Zitat",
      title: "",
      subtitle:
        "„Durch seine eigene langjährige Berufspraxis als Führungskraft verfügt er selbst über konkrete Beispiele und Erfahrungswerte, die das Coaching im Ergebnis gut greifbar und in die Praxis übertragbar machen.“",
      overlayStyle: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#007bc0;",
      },
      maxHeight: "320px",
      versionGallery: true,
    },
    {
      url: banner,
      alt: "Zitat",
      title: "",
      subtitle:
        "„Herr Willmann hat dabei das Können, stets die Bedürfnisse des Coachees aufzunehmen und durch seinen großen praktischen Erfahrungsschatz sowie durch seine exzellenten fachlichen Kenntnisse im Coachingbereich in ein wertvolles Beratungsgespräch zu übertragen.“",
      overlayStyle: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#007bc0;",
      },
      maxHeight: "320px",
      versionGallery: true,
    },
    {
      url: banner,
      alt: "Zitat",
      title: "",
      subtitle:
        "„Durch die ausgeprägte soziale Kompetenz, das Eingehen auf meine jeweiligen Belange sowie das konkrete Feedback ist es gelungen, alle Themen zielführend umzusetzen.“",
      overlayStyle: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#007bc0;",
      },
      maxHeight: "320px",
      versionGallery: true,
    },
    {
      url: banner,
      alt: "Zitat",
      title: "",
      subtitle:
        "„Wir hatten zu jedem Zeitpunkt das Gefühl, dass wir uns in guten Händen befinden und dabei die richtigen Dinge richtig tun.“",
      overlayStyle: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#007bc0;",
      },
      maxHeight: "320px",
      versionGallery: true,
    },
  ];
  return (
    <div>
      <Gallery imageList={images} autoSwipeInterval={10000} />

      <div style={{ padding: "20px" }} />

      <div className="main">
        <h2>Leistungen</h2>
        <br />
        <h3>Inhalte</h3>
        Gutes Coaching schafft Klarheit: Danach wissen Sie, was Sie tun wollen.
        Als <t>Coach für Ihr Berufsleben</t> begleite ich Sie dabei, stimmige
        Antworten auf Ihre offenen beruflichen Fragen zu finden. Diese Fragen
        stellen sich …
        <div className="rightSide" />
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            … ungeplant, wenn Sie zum Beispiel von heute auf morgen (ungesunden)
            Stress erleben, in eine Krise geraten:{"  "}
            <t>
              Coaching hilft Ihnen, Widerstandsfähigkeit auf- und auszubauen.
            </t>
          </div>
        </div>
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            … geplant, wenn Sie zum Beispiel eine neue Aufgabe, Rolle oder
            Funktion übernehmen:{"  "}
            <t>
              Coaching begleitet Sie dabei, Ihre Herausforderungen erfolgreich
              zu meistern.
            </t>
          </div>
        </div>
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            … immer wieder, wenn Sie zum Beispiel in Ihrem Berufsleben Ihre
            Werte und Ziele verfolgen und feststellen, dass diese in Ihrem
            Arbeitsalltag nicht immer umgesetzt werden können:{"  "}
            <t>
              Coaching unterstützt Sie, Ihren individuellen Weg zu finden und
              auf diesem zu bleiben.
            </t>
          </div>
        </div>
        <br />
        Gutes Coaching besitzt einerseits die Liebe zum Detail und betrachtet
        andererseits das große Ganze. Daher leuchten wir gemeinsam Ihre
        beruflichen Themen genau aus und setzen diese zudem in einen
        weiterführenden Zusammenhang. Wir berücksichtigen dabei …
        <div className="rightSide">
          <div className="sideColumn">
            <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
            <div className="columnPadding">
              … Ihre <t>Persönlichkeit:</t> Woher kommen Sie, wo stehen Sie und
              wo wollen Sie hin?
            </div>
          </div>
          <div className="sideColumn">
            <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
            <div className="columnPadding">
              … die <t>Personen in der Organisation</t>, für die Sie
              mitverantwortlich sind: Wie führen Sie sie, wie arbeiten Sie mit
              ihnen zusammen und wie gestalten Sie die Arbeitsbeziehung zu
              ihnen?
            </div>
          </div>
          <div className="sideColumn">
            <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
            <div className="columnPadding">
              … die <t>Organisation</t>, in der Sie tätig sind: Welche Mission
              und Strategien gelten, welche Strukturen und Kultur bestehen und
              was ist zu tun?{" "}
            </div>
          </div>
        </div>
      </div>
      <br />
      <ImageZoom src={Model} alt={"Modell"} originalWidth={"30%"} />
      <div className="main">
        <h3>Vorgehen</h3>
        Zuerst lernen wir uns in einem kostenlosen{" "}
        <t>Gespräch persönlich kennen</t>. Hier besprechen wir Ihr Anliegen und
        die Rahmenbedingungen für das Coaching. Anschließend entscheiden wir, ob
        wir zusammenarbeiten wollen. Danach beginnt Ihr individuelles Coaching.
        Dessen Gestaltung richtet sich dabei ausschließlich nach Ihnen und Ihrem
        Bedarf.
        <div className="rightSide" />
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            Zum Auftakt legen wir <t>Ihre Ziele</t> für unsere Zusammenarbeit
            fest: Welche Ergebnisse müssen am Ende vorliegen? Was muss sich für
            Sie verändern, damit das Coaching für Sie erfolgreich sein wird?
          </div>
        </div>
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            Dafür betrachten wir <t>Ihre Ausgangslage</t>; z. B. Ihre
            beruflichen Herausforderungen und Ihre persönliche Situation.
          </div>
        </div>
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            Auf dieser Grundlage skizzieren wir einen <t>Weg</t>, wie Sie Ihre
            gesetzten Ziele erreichen können. Wir überprüfen regelmäßig, ob die
            bisherigen Schritte zum erwünschten Erfolg geführt haben, ob der
            eingeschlagene Weg richtig oder ob eine Korrektur erforderlich ist.
          </div>
        </div>
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            Unsere Zusammenarbeit ist dann erfolgreich abgeschlossen, wenn Sie
            Ihre eingangs formulierten <t>Ziele erreicht</t> haben.
          </div>
        </div>
        <h2>Kommentare</h2>
      </div>

      <Gallery imageList={images1} autoSwipeInterval={10000} />
      <div className="main">
        <h2>Profil</h2>
        <br />
        Wann arbeiten Menschen gut und gerne? Wie sehen dauerhaft erfolgreiche
        Organisationen und Unternehmen aus?
        <br />
        <br />
        Diesen Fragen ging ich bereits in meinen <t>
          Universitätsausbildungen
        </t>{" "}
        nach. Sie trieben mich auch während meiner{" "}
        <t>Coaching- und Beratungsweiterbildungen</t> an. Die Theorie setzte ich
        in meiner
        <t> Berufslaufbahn</t> erfolgreich in die Praxis um: erst als
        Führungskraft und Vorstand eines Marktführers der
        Finanzdienstleistungsbranche, dann als Direktor einer internationalen
        Beratungsgesellschaft.
        <br />
        <br />
        Mir ist ein Umfeld wichtig, in dem ich mich fachlich und persönlich
        austauschen und Anregungen erfahren kann. Daher engagiere ich mich in
        verschiedenen <t> Berufsverbänden</t> und beruflichen <t>Netzwerken</t>.
        Auch als <t>Hochschullehrer</t> kann ich meine theoretischen und
        praktischen Erkenntnisse weitergeben und mir neue wissenschaftliche
        Gebiete erschließen.
        <br />
        <br />
        In unsere Zusammenarbeit als Ihr persönlicher Coach und Berater bringe
        ich nicht nur schlüs­sige theoretische Erkenntnisse und vielfältige
        praktische Erfahrungen ein. Vielmehr weiß ich aus meiner langjährigen
        Praxis, dass zudem ein{" "}
        <t>respektvoller, vertrauensvoller und auch anregender Umgang</t>{" "}
        zwischen Ihnen und mir Grundlage für den Erfolg unserer Arbeit ist:{" "}
        <t>Coaching für Ihr Berufsleben</t>.
      </div>
      <ScrollToTopButton />
      <Footer />
    </div>
  );
};

export default Home;
