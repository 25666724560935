import React, { Component } from "react";
import ImageOverlay from "../ImageOverlay/ImageOverlay.js";
import "./Gallery.css";
import ArrowRight from "../../Content/Icons/ArrowRight.svg";
import ArrowLeft from "../../Content/Icons/ArrowLeft.svg";

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImageIndex: 0,
    };
  }

  componentDidMount() {
    const { autoSwipeInterval } = this.props;
    if (autoSwipeInterval) {
      this.autoSwipeTimer = setInterval(this.goToNext, autoSwipeInterval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.autoSwipeTimer);
  }

  resetAutoSwipeTimer = () => {
    const { autoSwipeInterval } = this.props;
    if (autoSwipeInterval) {
      clearInterval(this.autoSwipeTimer);
      this.autoSwipeTimer = setInterval(this.goToNext, autoSwipeInterval);
    }
  };

  goToPrevious = () => {
    const { currentImageIndex } = this.state;
    const { imageList } = this.props;
    const newIndex =
      (currentImageIndex - 1 + imageList.length) % imageList.length;
    this.setState({ currentImageIndex: newIndex }, this.resetAutoSwipeTimer);
  };

  goToNext = () => {
    const { currentImageIndex } = this.state;
    const { imageList } = this.props;
    const newIndex = (currentImageIndex + 1) % imageList.length;
    this.setState({ currentImageIndex: newIndex }, this.resetAutoSwipeTimer);
  };

  ImageRender = () => {
    const { currentImageIndex } = this.state;
    const { imageList } = this.props;
    const currentImage = imageList[currentImageIndex];

    if (!currentImage) {
      return <p>No Image :/</p>;
    }

    const {
      url,
      alt,
      title,
      subtitle,
      overlayStyle,
      imageHeight,
      maxHeight,
      versionGallery,
    } = currentImage;

    return (
      <ImageOverlay
        url={url}
        alt={alt}
        title={title}
        subtitle={subtitle}
        overlayStyle={overlayStyle}
        imageHeight={imageHeight || "auto"}
        maxHeight={maxHeight}
        versionGallery={versionGallery}
      />
    );
  };

  render() {
    return (
      <div className="gallery-container">
        <button onClick={this.goToPrevious} className="left">
          <img src={ArrowLeft} alt="" className="arrow " height={90} />
        </button>
        <this.ImageRender />
        <button onClick={this.goToNext} className="right">
          <img src={ArrowRight} alt="" className="arrow" height={90} />
        </button>
      </div>
    );
  }
}

export default Gallery;
