import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavigationBar from "./Components/Navigation/NavigationBar.js";
import Home from "./Pages/Home/Home.js";
import ForWhom from "./Pages/ForWhom/ForWhom.js";
import What from "./Pages/What/What.js";
import Contact from "./Pages/Contact/Contact.js";
import Imprint from "./Pages/Imprint/Imprint.js";

function App() {
  return (
    <Router>
      <div>
        <NavigationBar />
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Leistungen" element={<What />} />
            <Route path="/Kommentare" element={<ForWhom />} />
            <Route path="/Profil" element={<Contact />} />
            <Route path="/Impressum" element={<Imprint />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
