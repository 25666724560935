import React, { Component } from "react";
import "./ScrollToTopButton.css";
import ArrowUp from "../../Content/Icons/ArrowUp.svg";

class ScrollToTopButton extends Component {
  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    return (
      <button onClick={this.scrollToTop} className="button">
        <img src={ArrowUp} alt="Arrow Up" width={70}></img>
      </button>
    );
  }
}

export default ScrollToTopButton;
