import "./Footer.css";
import { Link } from "react-router-dom";

import XingLogo from "../../Content/Icons/xingLogo.svg";
import LinkedinLogo from "../../Content/Icons/linkedinLogo.svg";

const Footer = () => {
  const MoveToImprint = () => {
    window.scrollTo({ top: 0 });
  };
  const MoveToDataprotection = () => {
    window.scrollTo({ top: 535 });
  };

  return (
    <div className="footer">
      <div className="sideColumn" id="one">
        <div>
          <a
            className="outLink"
            href="https://www.xing.com/profile/Thomas_Willmann14/web_profiles"
            target="_blank"
            rel="noreferrer"
          >
            <img src={XingLogo} alt="Xing Logo" className="footer-logo"></img>
          </a>
          <a
            className="outLink"
            href="https://www.linkedin.com/in/thomas-willmann-58b506147/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={LinkedinLogo}
              alt="Linkedin Logo"
              className="footer-logo"
            ></img>
          </a>
        </div>
        <div className="column2">
          E-Mail-Adresse: <br />
          <w>coaching@thomaswillmann.de </w> <br />
          Thomas Willmann <br /> Postfach 10 03 51 <br /> 74503 Schwäbisch Hall{" "}
          <br />
          +49 162 369 1223
        </div>
      </div>

      <div className="links">
        <Link onClick={MoveToImprint} to="/Impressum">
          Impressum
        </Link>
        <br />
        <Link onClick={MoveToDataprotection} to="/Impressum">
          Datenschutzerklärung
        </Link>
        <div className="copyright">&#169; 2024 Thomas WIllmann </div>
      </div>
    </div>
  );
};

export default Footer;
