import "./What.css";
import Footer from "../../Components/Footer/Footer";
import ScrollToTopButton from "../../Components/ScrollToTopButton/ScrollToTopButton";
import ImageZoom from "../../Components/ImageZoom/ImageZoom";

import Logo from "../../Content/Icons/Logo1.svg";
import Model from "../../Content/Icons/Model.svg";

const What = () => {
  const logoWidth = 30;

  return (
    <div>
      <div className="main">
        <div className="whiteSpace" />
        <h2>Leistungen</h2>
        <div className="whiteSpace" />
        <div className="whiteSpace" />
        Ich arbeite mit Menschen, die sich beruflich weiterentwickeln und ihr
        Berufsleben gestalten wollen. Sie kommen auf mich zu, weil sie stimmige
        Antworten auf ihre offenen beruflichen Fragen finden wollen. Folgende
        Themen bilden dabei Schwerpunkte:
        <div className="whiteSpace" />
        <h3>Widerstandsfähigkeit auf- und ausbauen.</h3>
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            Oft lösen <t>persönliche Konflikte</t> Stress und Krisen aus; zum
            Beispiel mit der Führungskraft, einer Kollegin oder einem Kollegen
            oder mit mehreren Menschen im eigenen Team.
          </div>
        </div>
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            Zudem werden Führungskräfte immer wieder mit{" "}
            <t>Konflikten innerhalb ihres Teams konfrontiert</t>, welche die
            Beteiligten selbst nicht (mehr) lösen können.
          </div>
        </div>
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            <t>Change- und Transformationsprozesse</t> bringen oft
            Verunsicherung mit sich. Auch ein <t>Projekt</t> in Schieflage,
            welches zu scheitern droht, kann ungesunden Stress erzeugen.
          </div>
        </div>
        <br />
        Im <t>Coaching für das Berufsleben</t> unterstütze ich Sie dabei, eine
        robuste <t>Widerstandsfähigkeit auf- und auszubauen</t>, damit Sie
        Herausforderungen nachhaltig begegnen. Dabei gilt es, den Ursachen auf
        den Grund zu gehen, die Situation zu stabilisieren und die beste
        Lösungsoption umzusetzen. Wenn sich dann der Stress gelegt hat und die
        Krise überwunden ist, schauen wir in die Zukunft und klären, was zu tun
        ist, damit sich Vergleichbares nicht wiederholt.{" "}
        <div className="whiteSpace" />
        <h3>Herausforderungen zielführend gestalten. </h3>
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            <t>Persönliche Veränderungen</t> gelingen besser mit einem Coaching
            im Vorfeld: Zum Beispiel wird beim Onboarding die Übernahme neuer
            Aufgaben oder neuer Rollen und Funktionen gezielt vorbereitet und
            begleitet.
          </div>
        </div>
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            <t>Organisationale Veränderungen</t> verlaufen erfolgreicher, wenn
            Unternehmen und Organisationen ausreichend Veränderungskompetenz
            besitzen: So ermöglichen leistungsfähige Führungsprozesse eine
            effektive und effiziente Steuerung der Veränderung. Wichtig ist aber
            auch, dass die Change-Leader die Veränderung in ihren
            Aufgaben­berei­chen rechtzeitig initiieren, aktiv gestalten und
            verlässlich verantworten.
          </div>
        </div>
        <br />
        Im <t>Coaching für das Berufsleben</t> begleite ich Sie dabei, Ihre
        beruflichen <t>Herausforderungen zielführend zu gestalten</t>. Dazu
        gehört die Analyse der Ausgangssituation und das Wissen, warum die
        Veränderung notwendig ist. Und wir halten im Coaching die Zukunftsvision
        fest, die Lust auf Umsetzung macht.
        <div className="whiteSpace" />
        <h3>Individuellen Weg finden und auf diesem bleiben. </h3>
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            Eine klare Haltung, die auf <t>Werten und Normen</t> gründet, hilft
            Menschen, Probleme und Herausforderungen im Berufsleben erfolgreich
            zu bewältigen. Wenn diese Werte und Normen erschüttert werden – zum
            Beispiel durch das Verhalten anderer Personen – führt das oft zu
            Ratlosigkeit.
          </div>
        </div>
        <br />
        Im <t>Coaching für das Berufsleben</t> sprechen wir über Ihre Werte und
        Normen, darüber, was Ihnen wichtig ist. Dabei gilt es, dass Sie sich
        wohlwollend – wenn auch kritisch – reflektieren, um dann auf Ihrem
        <t> eingeschlagenen Weg</t> zu bleiben oder diesen zu verlassen, um{" "}
        <t>neue Wege</t> zu gehen.
      </div>
      <div className="whiteSpace" />
      <div className="whiteSpace" />
      <div className="banner" />
      <div className="whiteSpace" />
      <div className="main">
        Damit das Coaching auch dauerhaft wirkt, leuchten wir gemeinsam ihre
        beruflichen Themen genau aus und setzen diese zudem in einen
        weiterführenden Zusammenhang. Dabei berücksichtigen wir folgende Themen:
        <div className="whiteSpace" />
        <h3>Persönlichkeit</h3>
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            Immer wieder stellen sich Menschen die Frage, wie sie sich{" "}
            <t>mittel- bis langfristig beruflich weiterentwickeln</t> sollen und
            wollen. Diese Fragen drängen sich ihnen vor allem auf, wenn ihre
            beruflichen Vorstellungen nicht (ganz) oder aber alle in Erfüllung
            gegangen sind. Sie fragen sich, was sie in ihrem Berufsleben – noch
            – erreichen möchten, welche Wünsche und Ziele sie – noch – haben,
            welche <t>Positionen</t> sie – noch – anstreben?
          </div>
        </div>
        <br />
        Im <t>Coaching für das Berufsleben</t> beantworten wir diese Fragen,
        indem wir Ihren <t>beruflichen Werdegang</t> betrachten und dabei den
        Gründen nachgehen, die zu Ihrer beruflichen Entwicklung führten. Dabei
        spielen Interessen, im Verlauf des Berufsleben entdeckte Potenziale,
        aber auch frühere berufliche Vorstellungen eine entscheidende Rolle.
        <div className="whiteSpace" />
        <h3>Personen in der Organisation</h3>
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            Eine Führungskraft ist vor allem für diejenigen{" "}
            <t>Personen in der Organisation</t> mitverantwortlich, die an sie
            berichten. Aber auch für Kolleginnen und Kollegen sowie für die
            eigene Führungskraft trägt man Mitverantwortung.
          </div>
        </div>
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            Die <t>Beziehungen</t> zwischen diesen Menschen sind eine
            wesentliche Grundlage für die Erfolge, die sie erzielen. Die
            Qualität dieser Beziehungen hängt davon ab, wie Personen führen und
            wie sie zusammenarbeiten.
          </div>
        </div>
        <br />
        Im <t>Coaching für das Berufsleben</t> gehen wir den Fragen nach, wer
        die <t>anderen Personen</t> in Ihrer Organisation <t>wirklich sind</t>,
        was sie motiviert, welchen Werten und Normen sie folgen und welche Ziele
        sie haben. Auf dieser Grundlage können Sie die Arbeitsbe­zie­hun­gen zu
        ihnen gestalten, sich gegenseitig bei ihren beruflichen und persönlichen
        Entwicklungen unterstützen.
        <div className="whiteSpace" />
        <h3>Organisation</h3>
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            <t>Organisationen und Organisationseinheiten</t> erfüllen immer
            einen bestimmten Zweck. Damit dieser den größtmöglichen Nutzen
            stiftet, benötigen sie die passende Strategie. Diese bestimmt,
            welche mittel- und kurzfristigen Ziele sie verfolgen, welche
            Maßnahmen sie ergreifen, um die Ziele zu erreichen, und welche
            Ressourcen sie dafür benötigen. Daraus leiten sie ihre Strukturen
            ab: Welche Aufgaben und Kompetenzen haben hat sie heute und in
            Zukunft?
          </div>
        </div>
        <div className="sideColumn">
          <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
          <div className="columnPadding">
            <t>Formate</t> stellen sicher, dass die Aufgaben und Kompetenzen
            einer Organisation oder Organisationseinheit zu Aufgaben und
            Kompetenzen der Personen passen, die in diesen arbeiten. Dafür ist
            es nötig, sich einen Überblick über gegenwärtige Aufgaben und
            zukünftige Ziele der jeweiligen Einheit zu verschaffen sowie über
            die Stärken, die mitarbeitende Personen besitzen. Daraus ergeben
            sich dann Entwicklungsszenarien für die jeweilige Einheit und die
            Personen.
          </div>
        </div>
        <br />
        Im <t>Coaching für das Berufsleben</t> geht es darum sicherzustellen,
        dass Ihre Organisation oder Organisationseinheit einen attraktiven
        Zweck, eine leistungsfähige Strategie und dazu passende Strukturen
        besitzt; und zudem, dass Sie Formate einsetzen, die die{" "}
        <t>Entwicklung Ihrer Einheit mit der Personalentwicklung</t> verbindet.
        <div className="whiteSpace" />
      </div>
      <ImageZoom src={Model} alt="Modell" originalWidth={400} />
      <ScrollToTopButton />
      <Footer />
    </div>
  );
};
export default What;
