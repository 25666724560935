import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../Content/Icons/Logo.svg";
import "./NavigationBar.css";

const NavigationBar = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: 10000, behavior: "smooth" });
  };

  const handleLinkClick = (action) => () => {
    action();
    toggleMenu();
  };

  return (
    <div>
      <div className="spacer" />
      <div className="navbar">
        <div className="navlogo">
          <Link
            to="/"
            className={location.pathname === "/" ? "active" : ""}
            onClick={scrollToTop}
          >
            <img src={Logo} alt="Logo" width="270" />
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          {isMenuOpen ? (
            <span style={{ fontSize: "30px" }}>&times;</span> // Close icon
          ) : (
            <span>&#9776;</span> // Hamburger menu icon
          )}
        </div>
        <div className={`nav-links ${isMenuOpen ? "active" : ""}`}>
          <Link
            to="/"
            className={location.pathname === "/" ? "active" : ""}
            onClick={handleLinkClick(scrollToTop)}
          >
            Überblick
          </Link>
          <Link
            to="/Leistungen"
            className={location.pathname === "/Leistungen" ? "active" : ""}
            onClick={handleLinkClick(scrollToTop)}
          >
            Leistungen
          </Link>
          <Link
            to="/Kommentare"
            className={location.pathname === "/Kommentare" ? "active" : ""}
            onClick={handleLinkClick(scrollToTop)}
          >
            Kommentare
          </Link>
          <Link
            to="/Profil"
            className={location.pathname === "/Profil" ? "active" : ""}
            onClick={handleLinkClick(scrollToTop)}
          >
            Profil
          </Link>
          <Link
            className={location.pathname === "" ? "active" : ""}
            onClick={scrollToBottom}
          >
            Kontakt
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
