import React from "react";
import "./Contact.css";
import Footer from "../../Components/Footer/Footer";
import Gallery from "../../Components/Gallery/Gallery";
import ScrollToTopButton from "../../Components/ScrollToTopButton/ScrollToTopButton";

import Portrait from "../../Content/Pictures/Profil.png";
import Logo from "../../Content/Icons/Logo1.svg";
import TW12 from "../../Content/Pictures/WillmannThomas12.jpg";
import TW18 from "../../Content/Pictures/WillmannThomas18.jpg";
import TW38 from "../../Content/Pictures/WillmannThomas38.jpg";
import TW39 from "../../Content/Pictures/WillmannThomas39.jpg";
import TW53 from "../../Content/Pictures/WillmannThomas53.jpg";
import TW64 from "../../Content/Pictures/WillmannThomas64.jpg";
import TW80 from "../../Content/Pictures/WillmannThomas80.jpg";
import TW88 from "../../Content/Pictures/WillmannThomas88.jpg";
import TWP20 from "../../Content/Pictures/WillmannThomasPortraitserie20.jpg";
import TWP70 from "../../Content/Pictures/WillmannThomasPortraitserie70.jpg";
import TWP99 from "../../Content/Pictures/WillmannThomasPortraitserie99.jpg";

const Contact = () => {
  const images = [
    {
      url: TW12,
      alt: "Thomas Willmann",
      title: "",
      subtitle: "",
      overlayStyle: {
        top: "70%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#ffffff",
      },
      imageHeight: "500px",
      maxHeight: "",
      versionGallery: false,
    },

    {
      url: TW18,
      alt: "Thomas Willmann",
      title: "",
      subtitle: "",
      overlayStyle: {
        top: "70%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#ffffff",
      },
      imageHeight: "500px",
      maxHeight: "500px",
      versionGallery: false,
    },
    {
      url: TWP70,
      alt: "Thomas Willmann",
      title: "",
      subtitle: "",
      overlayStyle: {
        top: "70%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#ffffff",
      },
      imageHeight: "500px",
      maxHeight: "500px",
      versionGallery: false,
    },
    {
      url: TW38,
      alt: "Thomas Willmann",
      title: "",
      subtitle: "",
      overlayStyle: {
        top: "70%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#ffffff",
      },
      imageHeight: "500px",
      maxHeight: "500px",
      versionGallery: false,
    },

    {
      url: TW64,
      alt: "Thomas Willmann",
      title: "",
      subtitle: "",
      overlayStyle: {
        top: "70%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#ffffff",
      },
      imageHeight: "500px",
      maxHeight: "500px",
      versionGallery: false,
    },

    {
      url: TW88,
      alt: "Thomas Willmann",
      title: "",
      subtitle: "",
      overlayStyle: {
        top: "70%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#ffffff",
      },
      imageHeight: "500px",
      maxHeight: "500px",
      versionGallery: false,
    },
    {
      url: TWP20,
      alt: "Thomas Willmann",
      title: "",
      subtitle: "",
      overlayStyle: {
        top: "70%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#ffffff",
      },
      imageHeight: "500px",
      maxHeight: "500px",
      versionGallery: false,
    },

    {
      url: TWP99,
      alt: "Thomas Willmann",
      title: "",
      subtitle: "",
      overlayStyle: {
        top: "70%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#ffffff",
      },
      imageHeight: "500px",
      maxHeight: "500px",
      versionGallery: false,
    },
    {
      url: TW39,
      alt: "Thomas Willmann",
      title: "",
      subtitle: "",
      overlayStyle: {
        top: "70%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#ffffff",
      },
      imageHeight: "500px",
      maxHeight: "500px",
      versionGallery: false,
    },
    {
      url: TW53,
      alt: "Thomas Willmann",
      title: "",
      subtitle: "",
      overlayStyle: {
        top: "70%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#ffffff",
      },
      imageHeight: "500px",
      maxHeight: "500px",
      versionGallery: false,
    },

    {
      url: TW80,
      alt: "Thomas Willmann",
      title: "",
      subtitle: "",
      overlayStyle: {
        top: "70%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        titleColor: "#ffffff",
        titleFontSize: "36px",
        subtitleColor: "#ffffff",
      },
      imageHeight: "500px",
      maxHeight: "500px",
      versionGallery: false,
    },
  ];
  const logoWidth = 30;
  return (
    <div>
      <div className="main">
        <img
          src={Portrait}
          alt="Thomas Willmann"
          width={300}
          className="center"
        />
        <h2 className="titel">Profil</h2>
        <div className="whiteSpace" />
        Wann arbeiten Menschen gut und gerne? Wie sehen dauerhaft erfolgreiche
        Organisationen und Unternehmen aus?
        <div className="whiteSpace" />
        <h3>Ausbildungen: Organisationen, Unternehmen und Menschen</h3>
        Diesen Fragen ging ich bereits in meinen{" "}
        <t>
          sozial- und wirtschaftswissenschaftlichen Universitätsausbildungen
        </t>{" "}
        sowie meinen Coach­ing- und Beratungsweiterbildungen nach. Schließlich
        beschäftigt sich Soziologie unter anderem mit der Frage, wie
        Organisationen und Unternehmen funktionieren; Politikwissenschaft unter
        anderem mit der Frage, wie Entscheidungsprozesse in Organisationen und
        Unternehmen funktionieren; und Management vor allem mit der Frage, wie
        dieses Funktionieren in Organisationen und Unternehmen effektiv und
        effizient erfolgt.
        <br />
        <br />
        <div className="sideColumn1">
          <div className="uncolored">
            <div>
              Meine Universitätsausbildungen ergänzte ich durch{" "}
              <t>Coaching-, Beratungs- und Therapieweiterbildungen</t>. Hier
              beschäftigte ich mich mit dem Menschen, der Organisationen und
              Unternehmen entwickelt und Entscheidungen trifft; der dabei für
              sein Berufsleben persönliche Visionen und Ziele verfolgt, der
              seine individuellen Potenziale ausschöpfen und Stärken einbringen
              will; der seine Fähigkeiten und Fertigkeiten auf- und ausbauen und
              sich weiterentwickeln will – und der einen Sinn in seinen
              beruflichen Aufgaben sucht.
            </div>
          </div>
          <div className="colored">
            <div>
              An der Universität Konstanz studierte ich{" "}
              <t>Soziologie und Politikwissenschaft</t> (Magister Artium).
              Gutachter meiner Magisterarbeit waren Thomas Luckmann und
              Hans-Georg Soeffner. Am Management Zentrum St. Gallen, Schweiz,
              absolvierte ich ein
              <t> General Management-Studium</t> (Master of Advanced
              Management). Wissenschaftlicher Leiter dieser Ausbildung war
              Fredmund Malik. Weiterbilden ließ ich mich am Helm Stierlin
              Institut zum
              <t> Systemischen Therapeuten und Berater</t> (Abschluss Ende 2024)
              sowie an der dehner academy zum <t>Coach</t>, bei Schwäbisch
              Hall-Training zum <t>Mentor</t> und bei Gerd Ruckwied in{" "}
              <t>Lean Management und Six Sigma</t> sowie bei brainbirds in{" "}
              <t>Agilen Management-Methoden</t>.
            </div>
          </div>
        </div>
        <div className="whiteSpace" />
        <h3>Berufserfahrung: Führung, Management und Transformation</h3>
        Praktische Erfahrungen sammelte ich als{" "}
        <t>
          Führungskraft und Vorstand in der Unternehmensgruppe der Bausparkasse
          Schwäbisch Hall
        </t>{" "}
        sowie als <t>Direktor am Management Zentrum St. Gallen</t>. Neben meiner
        disziplinarischen Verantwortung leitete ich zahlreiche
        Transformationsvorhaben – und zwar in unterschiedlichen Rollen: als
        Leiter unternehmensweiter Fach- und IT-Großprojekte, als Product Owner
        in Agilen Projekten sowie als Lean Manager bei Prozessoptimierungen.
        Dabei habe ich eine Vielfalt von Persönlichkeits-, Personal- und
        Organisationsentwicklungen erlebt und begleitet beziehungsweise
        verantwortet und gestaltet.
        <div className="whitespace"></div>
        <div className="whiteSpace" />
        <h3>
          Netzwerke: persönlicher, fachlicher und wissenschaftlicher Austausch
        </h3>
        Als Mitglied verschiedener <t>Berufsverbände</t> (zum Beispiel der
        Deutschen Gesellschaft für Systemische Therapie, Beratung und
        Familientherapie, DGSF) <t>und beruflicher Netzwerke</t> (zum Beispiel
        der Baden-Badener Unternehmergespräche) pflege ich den Kontakt zu
        Praktikern, da mir ein Umfeld wichtig ist, in dem ich mich fachlich und
        persönlich austauschen und Anregungen erfahren kann. Als{" "}
        <t>Hochschullehrer an der University of Applied Management Studies</t>{" "}
        in Mannheim lehre ich rund um die Themen der Personal- und
        Unternehmensführung sowie der Persön­lich­keits­entwicklung. Hier kann
        ich meine theoretischen und praktischen Erkenntnisse weitergeben und mir
        neue wissenschaftliche Gebiete erschließen.
        <div className="whitespace"></div>
        <div className="whiteSpace" />
        <h3>Persönlicher Umgang: respektvoll, wertschätzend, anregend</h3>
        Für mich ist es selbst­verständ­lich, dass ich meinen Klientinnen und
        Klienten immer respektvoll begegne und mich mit ihren Anliegen
        wertschätzend auseinandersetze. Dabei will ich Schweres leichter machen,
        anregen – und konstruktiv aufregen. Denn als Coach mit langjähriger
        Erfahrung weiß ich, dass nicht nur schlüssige theoretische Erkenntnisse
        und vielfältige praktische Erfahrungen die Grundlage für den Erfolg
        meiner Arbeit sind, sondern auch eine gute und vertrauensvolle
        Geschäftsbeziehung.
        <div className="whiteSpace"></div>
        <div className="whiteSpace" />
        <div className="colored1">
          Immer wieder bin ich auch als <t>Referent und Autor</t> tätig. Hier
          ein Auszug aus diesen Aktivitäten:
          <div className="whiteSpace"></div>
          <div className="sideColumn">
            <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
            <div className="columnPadding">
              Vortrag:{" "}
              <t>
                „Agile Management of Digital Change: Organisations- und
                Personalentwicklung im Change“
              </t>
              ; auf Einladung der otto group
            </div>
          </div>
          <div className="sideColumn">
            <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
            <div className="columnPadding">
              Vortrag:{" "}
              <t>„Von der Manufaktur zu einer Bestleistungsorganisation“</t>;
              auf Einladung des Kompetenznetzwerks „Krankenhausmanagement“
            </div>
          </div>
          <div className="sideColumn">
            <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
            <div className="columnPadding">
              Vortrag: <t>„Lean Management bei einem Finanzdienstleister“</t>;
              auf Einladung der Hochschule für angewandte Wissenschaften,
              München
            </div>
          </div>
          <div className="sideColumn">
            <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
            <div className="columnPadding">
              Fachaufsätze, z. B.{" "}
              <t>
                „Erfolgsfaktor Marktfolge: Die Kosten senken und die
                Kundenansprüche durch Prozessoptimierungen und
                Mitarbeiterqualifizierungen übertreffen“
              </t>
              ; in „Ideen- und Innovationsmanagement“, 07/2015
            </div>
          </div>
          <div className="sideColumn">
            <img src={Logo} alt="Logo" width={logoWidth} className="logo" />
            <div className="columnPadding">
              Wissenschaftliche Aufsätze, z. B. zu{" "}
              <t>telefonischen Beratungsgesprächen im Hörfunk</t>, in
              „Kommunikative Lebenswelten“, Hrsg. Hubert Knoblauch,
              Universitätsverlag Konstanz, 1996
            </div>
          </div>
        </div>
        <div className="whiteSpace"></div>
        <div className="whiteSpace" />
        <Gallery imageList={images} autoSwipeInterval={10000} />
      </div>

      <ScrollToTopButton />
      <Footer />
    </div>
  );
};
export default Contact;
